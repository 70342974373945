.Brand {
  display: flex;
  height: 100%;
  align-items: center;
  vertical-align: middle;
  margin-bottom: 0;
  justify-content: space-between;
}

.Brand img {
  vertical-align: middle;
  margin-bottom: 0 !important;
}

.Brand .title {
    color: white;
    text-decoration: none;
    font-size: 1.4rem;
    font-family: 'Zeroes_1 W05 Regular', sans-serif;
    letter-spacing: 3px;
}
